import React from 'react';
import './ExploreContainer.css';
import {IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonLabel, IonText, IonItem} from "@ionic/react";
import {warning} from "ionicons/icons";
import useStore from "../StateStore";

const EmployeeInfo: React.FC = () => {
    const employee = useStore(state => state.employee)

    return <IonItem color={"light"}><IonLabel>
        <h2>
            <b>
                <IonText className={"ion-text-center ion-padding"}>{employee?.vorname} {employee?.nachname} ( {employee?.employeeId} )</IonText>
            </b>
        </h2>
    </IonLabel></IonItem>
};

export default EmployeeInfo;
