import React from 'react';
import './ExploreContainer.css';
import {IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import { warning } from "ionicons/icons";

interface ContainerProps {
}

const FaraError: React.FC<ContainerProps> = () => {
    const {t} = useTranslation();

    const history = useHistory()

    return <>
        <h1 className="ion-center">{t('generalError')}</h1>

        <IonGrid>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonIcon color="danger" style={{fontSize: "5em"}} icon={warning}/>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonButton color="favorite" className="" onClick={() => {
                        history.go(0)
                    }}>{t('retry')}</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>

    </>
};

export default FaraError;
