import {useTranslation} from "react-i18next";
import {useAuth} from "./hooks/use-auth";
import {useHistory} from "react-router";
import {IonCol, IonFab, IonFabButton, IonGrid, IonIcon, IonRow, useIonRouter} from "@ionic/react";
import useStore from "./StateStore";
import React, {useEffect, useRef, useState} from "react";
import {checkmark, close} from "ionicons/icons";
// @ts-ignore
import SignaturePad from 'react-signature-pad-wrapper';

export function FaraSignature(props: { onClick: (signaturePad: any) => void }) {
    const {t} = useTranslation();
    const history = useHistory()

    const [canSend, setCanSend] = useState(false);

    let signaturePad = useRef<any>()

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    })

    return <>
        <IonGrid>
            <IonRow className=" ion-align-items-stretch">
                <IonCol className="ion-no-margin">
                    <div className="signatureborder">
                        <SignaturePad ref={(ref: React.MutableRefObject<any>) => signaturePad = ref}
                                      options={{backgroundColor: "rgb(255,255,255)", onBegin: () => setCanSend(true)}}/>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <h1 className="">
                        ↑
                        <p>
                            {t('pleaseSignHere')}
                        </p>
                    </h1>
                </IonCol>
            </IonRow>
            <IonRow className=" ion-align-items-stretch">
                <IonCol className="ion-no-margin ion-center">

                </IonCol>
            </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => history.goBack()}>
                <IonIcon icon={close}/>
            </IonFabButton>
        </IonFab>
        <IonFab vertical="bottom" horizontal="start" slot="fixed">
            <IonFabButton color={"success"} disabled={!canSend} onClick={() => props.onClick(signaturePad)}>
                <IonIcon icon={checkmark}/>
            </IonFabButton>
        </IonFab>
    </>;
}

export function dataURLtoBlob(dataurl: string) {
    // @ts-ignore
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}