import {
    IonBackButton,
    IonBadge,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter,
    IonSegment,
    IonSegmentButton,
    IonFabButton,
    IonFab,
    IonIcon,
    IonButton,
    IonItemGroup,
    IonItemDivider, IonAlert, useIonRouter, IonLoading, useIonAlert
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import '../Home.css';
import useStore, {FaraState, TimeTrackType} from '../../StateStore'
import useFetch, {CachePolicies, CustomOptions, IncomingOptions} from "use-http";
import {useTranslation} from "react-i18next";
import FaraLoading from "../../components/FaraLoading";
import FaraError from "../../components/FaraError";
import useLocalStorage from "../../hooks/use-localstorage";
import {add, checkmark, pencil, helpCircleOutline, time, paperPlane, trashBin, trash} from 'ionicons/icons';
import {useAuth} from "../../hooks/use-auth";
import {RouteComponentProps} from "react-router";
import moment from "moment";
import 'moment/locale/de';

const TimeTrackViewer: React.FC<RouteComponentProps<{ id: string }>> = ({match}) => {
    const {t} = useTranslation();
    const auth = useAuth();
    const timeSheets = useStore(state => state.timesheets)
    const store = useStore()
    const [showHelp, setshowHelp] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const router = useIonRouter()
    const [isLoading, setIsLoading] = useState(false);

    const [presentAlert] = useIonAlert();

    moment.locale('de');

    useIonViewWillEnter(() => {
        setIsLoading(true)
        store.fetchTimeSheets(auth).then(() => setIsLoading(false))
    });

    function deleteItem() {
        store.deleteTimeSheet(match.params.id).then(() => {
            router.goBack()
        })
    }

    function getTimeSheet() {
        return timeSheets?.filter(x => match.params.id === x.id)[0];
    }

    return (
        <IonPage>
            <IonAlert
                isOpen={showHelp}
                onDidDismiss={() => setshowHelp(false)}
                header={t('alert')}
                backdropDismiss={false}
                message={t('timesheetHelp')}
                buttons={[
                    {
                        text: 'Ok',
                    }]}
            />
            <IonAlert
                isOpen={showConfirm}
                onDidDismiss={() => setShowConfirm(false)}
                header={t('alert')}
                backdropDismiss={false}
                message={t('confirmDelete')}
                buttons={[
                    {
                        text: t('ok'),
                        handler: deleteItem,
                    },
                    {
                        text: t('cancel'),
                        role: 'cancel'
                    },
                ]}
            />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={t('back')} defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>{t('timetrack')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading isOpen={isLoading}/>
                <div className={"ion-padding ion-center"}>
                    <IonButton fill={"solid"} disabled={getTimeSheet()?.einzelnachweise?.length === 0} className={"ion-margin-vertical"} routerLink={`/timetrack/complete/${match.params.id}`}>
                        <IonIcon slot="start" icon={paperPlane}/>
                        {t('complete')}
                    </IonButton>

                    {/*<IonButton fill={"clear"} color={"medium"} className={"ion-margin-vertical"} onClick={() => {setshowHelp(true)}}>*/}
                    {/*    <IonIcon icon={helpCircleOutline} size={"large"}/>*/}
                    {/*</IonButton>*/}

                    <IonButton slot={"start"} fill={"outline"} onClick={() => setShowConfirm(true)}>
                        <IonIcon icon={trash} />
                    </IonButton>
                </div>

                <IonList>
                    {Array.from(Array(7).keys()).map(index => <WeekdayItem key={index}
                                                                           timeSheet={getTimeSheet()} day={index + 1}/>)}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

function WeekdayItem(props: any) {
    if (!props.timeSheet) return <FaraLoading />

    return <>
        <WeekdayDivider timeSheet={props.timeSheet} day={props.day}/>
        {filterEintraege(props.timeSheet.einzelnachweise, props.day).map(x => <TimeTrackItem key={x.id} eintrag={x} timeSheetId={props.timeSheet.id}/>)}
        <NewEntryItem day={props.day} forId={props.timeSheet.id} kw={moment(props.timeSheet.von).format("W")}/>
    </>;
}

function WeekdayDivider(props: any) {
    const {t} = useTranslation();
    function getOverallHours(einzelnachweise: TimeTrackType[]) {
        return einzelnachweise.map(x => moment.duration(moment(x.bis).diff(moment(x.von))).subtract(x.pause, "minutes").asHours())
            .reduce((acc, current) => acc + current, 0)
    }

    function getPausen(einzelnachweise: TimeTrackType[]) {
        return einzelnachweise.map(x => x.pause)
            .reduce((acc, current) => acc + current, 0)
    }

    let einzelnachweise = filterEintraege(props.timeSheet.einzelnachweise, props.day);

    return <IonItemDivider mode={"ios"} className={"weekdaydivider"}>
        <IonLabel>{moment(props.timeSheet.von).day(props.day).format("dddd D.M.YYYY")}</IonLabel>
        <IonLabel slot="end" hidden={!einzelnachweise || einzelnachweise.length === 0} className={"ion-margin-vertical ion-text-right ion-margin-end"}>
            <p>{t('overallHours')}: {getOverallHours(einzelnachweise).toFixed(2)}std</p>
            <p>{t('breaktimeHours')}: {getPausen(einzelnachweise).toFixed(0)}min</p>
        </IonLabel>
    </IonItemDivider>;
}

function TimeTrackItem(props: {eintrag: TimeTrackType, timeSheetId: string}) {
    const {t} = useTranslation();
    return <IonItem detail={true} routerLink={`/timetrack/edit/${props.timeSheetId}/${props.eintrag.id}/${moment(props.eintrag.von).format("W")}`}>
        <IonLabel>
            <h2>{moment(props.eintrag.von).format("HH:mm")} - {moment(props.eintrag.bis).format("HH:mm")}</h2>
            {props.eintrag.kostenstelle && <h3>{t('costCentre')}: {props.eintrag.kostenstelle}</h3>}
        </IonLabel>
        <IonLabel slot="end" className={"ion-text-right"}>
            <h2>{t('breaktime')}: {props.eintrag.pause}min</h2>
        </IonLabel>
    </IonItem>
}

function NewEntryItem(props: { day: string, forId: string, kw: string}) {
    return <IonItem>
        <IonFabButton className={"ion-margin-vertical fabBottom"} routerLink={`/timetrack/add/${props.forId}/${props.day}/${props.kw}`}>
            <IonIcon icon={add}/>
        </IonFabButton>
    </IonItem>;
}

function filterEintraege(einzelnachweise: TimeTrackType[], day: number) {
    return einzelnachweise.filter(x => moment(x.von).day() === day).sort((a,b) => moment(a.von).valueOf() - moment(b.von).valueOf())
}

export default TimeTrackViewer;
