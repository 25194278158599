import {
    IonButton,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenu,
    IonList,
    IonItem,
    IonImg,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonRouterOutlet,
    IonApp,
    IonButtons,
    IonMenuButton,
    IonSplitPane,
    IonToast,
    IonInput,
    IonFooter,
    IonText,
    IonLabel, isPlatform
} from '@ionic/react';
import React, {useRef, useState} from 'react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import {key, logIn, star} from 'ionicons/icons';
import {IonReactRouter} from "@ionic/react-router";
import {Redirect, Route, useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useAuth} from "../hooks/use-auth";
import HomescreenLogo from "./HomescreenLogo";

const Login: React.FC = () => {
    const {t} = useTranslation();
    const [isAuth, setIsAuth] = useState(false);
    const [initializationError, setInitializationError] = useState({message: ""});
    const history = useHistory();
    const [login, setLogin] = useState<any>();
    const [password, setPassword] = useState<any>();
    const [errorInfo, setErrorInfo] = useState({showErrorToast: false, errMsg: ""});
    const auth = useAuth()

    const passwordRef = useRef(null)

    const doLogin = async () => {
        try {
            auth.signin(login.trim(), password.trim())
                .then(() => {
                    console.log("home push")
                    history.push("/home");
                })
                .catch((error: any) => {
                    setErrorInfo({showErrorToast: true, errMsg: error.message});
                })
        } catch (e) {
            setErrorInfo({showErrorToast: true, errMsg: e.message});
            return false;
        }
    };

    // @ts-ignore
    return (
        <IonPage>
            {/*<IonHeader>*/}
            {/*    <IonToolbar>*/}
            {/*        <IonButtons slot="start" />*/}
            {/*        <IonTitle>{t('login')}</IonTitle>*/}
            {/*    </IonToolbar>*/}
            {/*</IonHeader>*/}
            <IonContent className="ion-padding">
                <section className="ion-margin">
                    <HomescreenLogo/>
                </section>

                <IonText color="danger" style={{fontWeight: "500"}}>
                    {initializationError && initializationError.message}
                </IonText>

                    <IonItem>
                        <IonLabel position="floating">{t('loginlabel')}</IonLabel>
                        <IonInput autofocus={true}
                                  enterkeyhint={"next"}
                                  onIonChange={(e) => {
                                      setLogin(e.detail.value);
                                  }}
                                  onKeyPress={e => {
                                      if (e.key === 'Enter') {
                                          // @ts-ignore
                                          passwordRef.current.setFocus()
                                          return true
                                      }
                                  }}
                                  name="email"
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t('password')}</IonLabel>
                        <IonInput
                            ref={passwordRef}
                            type="password"
                            enterkeyhint={"enter"}
                            onIonChange={(e) => {
                                setPassword(e.detail.value);
                            }}

                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    // @ts-ignore
                                    passwordRef.current.setBlur()
                                    doLogin()
                                }
                            }}
                            name="password"
                        />
                    </IonItem>
                    <div style={{padding: 10, paddingTop: 20}}>
                        <IonButton
                            color="farared"
                            expand="block"
                            disabled={!login || !password}
                            onClick={(e) => {
                                if (!e.currentTarget) {
                                    return;
                                }
                                e.preventDefault();
                                doLogin();
                            }}
                        >
                            {isAuth ? "Logged In" : t('login')}
                        </IonButton>
                    </div>
                    <hr />
                    <div style={{padding: 10, paddingTop: 20}}>
                        <IonButton
                            color="farared"
                            expand="block"
                            hidden={!isPlatform('android') && !isPlatform('ios') }
                            routerLink={`/bewerber/false`}
                            fill={"outline"}
                        >{hasBewerberUuid() ? t('checkApplicationStatus') : t('applyNow')}
                        </IonButton>
                        <IonButton
                            color="farared"
                            expand="block"
                            hidden={isPlatform('android') || isPlatform('ios') }
                            fill={"outline"}
                            href={"https://www.fara.de/bewerber/jobsuche#bewerbungsformular"}
                            target={"_blank"}
                        >{t('applyNow')}
                        </IonButton>
                    </div>
                <IonToast
                    color="danger"
                    isOpen={errorInfo.showErrorToast}
                    onDidDismiss={() => setErrorInfo({errMsg: "", showErrorToast: false})}
                    message={errorInfo.errMsg}
                    duration={2000}
                />
            </IonContent>
        </IonPage>
    );
};

function hasBewerberUuid() {
    return localStorage.getItem("uuid") !== null
}

export default Login;
