import {
    IonBackButton,
    IonBadge,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter,
    IonSegment,
    IonSegmentButton,
    IonFabButton,
    IonFab,
    IonIcon,
    IonButton,
    IonDatetime,
    IonInput,
    IonItemDivider,
    IonRow,
    IonCol
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import '../Home.css';
import useStore, {FaraState, TimeSheetType} from '../../StateStore'
import useFetch, {CachePolicies, CustomOptions, IncomingOptions} from "use-http";
import {useTranslation} from "react-i18next";
import FaraLoading from "../../components/FaraLoading";
import FaraError from "../../components/FaraError";
import useLocalStorage from "../../hooks/use-localstorage";
import {add, checkmark, list, pencil, trashBin} from 'ionicons/icons';
import {useAuth} from "../../hooks/use-auth";
import EmployeeInfo from "../../components/EmployeeInfo";
import moment from "moment";
import {FaraCenterCenter} from "../../components/FaraCenterCenter";
import {FaraHorizontalCenter} from "../../components/FaraHorizontalCenter";

const TimeSheetList: React.FC = () => {
    const {t} = useTranslation();
    const auth = useAuth();
    // const timesheets = useStore(state => state.timesheets)

    // const { loading, error, data} = useFetch(`${process.env.REACT_APP_API_URL}/timetrack`, {
    //     headers: {"Authorization": `Bearer ${localStorage.getItem('login')}`},
    //     cachePolicy: CachePolicies.NO_CACHE
    // },)

    const store = useStore()
    const timesheets = useStore(state => state.timesheets)

    useIonViewWillEnter(() => {
        store.fetchTimeSheets(auth)
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={t('back')} defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>{t('timetrack')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <EmployeeInfo />
                {!timesheets && <FaraLoading />}
                {timesheets && timesheets.length === 0 && <EmptyTimeSheetsHelper />}
                {timesheets && timesheets.length > 0 && <IonList className={"ion-padding"}>
                    <h3>{t('openTimeSheets')}</h3>
                    {timesheets.map((item: TimeSheetType) =>
                        <IonItem detail={true} key={item.id} routerLink={`/timetrack/open/${item.id}`}>
                            <IonLabel>
                                {t('TIMESHEET')} KW {moment(item.von).format("W")}
                                <p>{moment(item.von).format("DD.MM.YYYY")} - {moment(item.bis).format("DD.MM.YYYY")}</p>
                                <p>{t('customer')}: {item.kunde}</p>
                            </IonLabel>
                        </IonItem>)
                    }
                </IonList>}
                <IonFab vertical="center" horizontal="end" slot="fixed">
                    <IonFabButton routerLink={"/timetrack/addsheet"}>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

function EmptyTimeSheetsHelper() {
    const {t} = useTranslation();

    return <>
        <FaraHorizontalCenter>
            <h3>{t('emptyTimeSheetsHelp')}</h3>
        </FaraHorizontalCenter>
    </>;
}

export default TimeSheetList;
