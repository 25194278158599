import React from 'react';
import './ExploreContainer.css';
import {IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner} from "@ionic/react";
import {warning} from "ionicons/icons";

interface ContainerProps {
}

// @ts-ignore
export const FaraHorizontalCenter = ({children}) => {
    return <IonGrid>
        <IonRow className="ion-justify-content-center">
            <IonCol className="ion-text-center">
                {children}
            </IonCol>
        </IonRow>
    </IonGrid>
}
