import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import deTrans from './locales/de/translation.json'
import plTrans from './locales/pl/translation.json'
import enTrans from './locales/en/translation.json'


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources: {
            de: {
                translation: deTrans
            },
            pl: {
                translation: plTrans
            },
            en: {
                translation: enTrans
            }
        },
        fallbackLng: 'de',
        //lng: "de",
        debug: true,

        detection: {caches: []},

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: true
        }
    });
i18n.changeLanguage()

// i18n.addResourceBundle('de', 'translations', resources, true, true);

export default i18n;