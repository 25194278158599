import {
    IonAlert,
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonRouter,
    useIonViewWillEnter
} from '@ionic/react';
import React, {useState} from 'react';
import {Page} from 'react-pdf';
// @ts-ignore
import {Document} from 'react-pdf/dist/esm/entry.webpack';
import './Home.css';
import {RouteComponentProps} from "react-router";
import {Plugins} from "@capacitor/core";
import {download, pencil, share as shareIcon} from 'ionicons/icons';
import FaraError from "../components/FaraError";
import FaraLoading from "../components/FaraLoading";
import {useTranslation} from "react-i18next";
import {useAuth} from "../hooks/use-auth";
import useStore from "../StateStore";
// @ts-ignore
import PinchToZoom from 'react-pinch-and-zoom';
// @ts-ignore
import {PDFDocumentProxy} from "pdfjs-dist";

const DocumentViewer: React.FC<RouteComponentProps<{ id: string }>> = ({match}) => {
    const [numPages, setNumPages] = useState<number>(0);
    const [pdfData, setPdfData] = useState<Uint8Array>();
    const {t} = useTranslation();
    const auth = useAuth()
    const router = useIonRouter();
    const store = useStore()
    const [showAlert1, setShowAlert1] = useState(false);

    // const [reloadPdf, setReloadPdf] = useState(false);

    const [urlObj, setUrlObj] = useState<object>({});

    // let urlObjMemo = null
    // console.log("globalliiii "+ urlObjMemo)

    useIonViewWillEnter(() => {
        setUrlObj({ url: `${process.env.REACT_APP_API_URL}/document/download/${match.params.id}`,
            httpHeaders: { 'Authorization': `Bearer ${auth.login}` }
        })
    });

    // useEffect(() => {
    //     // console.log("effekt" +reloadPdf)
    //     // setReloadPdf(!reloadPdf)
    //     setUrlObj({ url: `${process.env.REACT_APP_API_URL}/document/download/${match.params.id}?${reloadPdf}`,
    //         httpHeaders: { 'Authorization': `Bearer ${auth.login}` }
    //     })
    // }, [])

    // const urlObjMemo = useMemo(() => ({ url: `${process.env.REACT_APP_API_URL}/document/download/${match.params.id}?${reloadPdf}`,
    //     httpHeaders: { 'Authorization': `Bearer ${auth.login}` }
    // }), [match.params.id, auth.login, reloadPdf])

    function onDocumentLoadSuccess(x : any) {
        setNumPages(x.numPages);
        // @ts-ignore
        x.getData().then(x => setPdfData(x))
    }

    function shareButtonClicked() {
        if (pdfData) {
            const base64 = btoa(
                pdfData.reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            Plugins.FileSharer.share({
                filename: "dok.pdf",
                base64Data: base64,
                contentType: "application/pdf",
            }).then(() => {
                // do sth
            }).catch((error: { message: any; }) => {
                console.error("File sharing failed", error.message);
            });
        }
    }

    function downloadButtonClicked() {
        if (pdfData) {
            const base64 = btoa(
                pdfData.reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return "data:application/pdf;base64,"+base64
        }
    }

    function backButtonClicked() {
        if (needSig()) {
            setShowAlert1(true)
        } else {
            setShowAlert1(false)
            router.back()
        }
    }

    function needSig() {
        return needCustomerSig() || needEmployeeSig()
    }

    function needEmployeeSig() {
        let found = findDocument();
        return found?.employeeSignature?.status === "NEED_SIGNATURE";
    }

    function needCustomerSig() {
        let found = findDocument();
        return found?.customerSignature?.status === "NEED_SIGNATURE";
    }

    function findDocument() {
        return store.employee?.documents?.find((x) => x.id === match.params.id);
    }

    return (
        <IonPage>
            <IonAlert
                isOpen={showAlert1}
                onDidDismiss={() => setShowAlert1(false)}
                header={t('alert')}
                backdropDismiss={false}
                message={t('signWarnning')}
                buttons={[
                    {
                        text: 'Ok',
                    },
                    {
                        text: t('cancel'),
                        role: 'cancel',
                        handler: (e) => {
                            router.back()
                        }
                    }]}
            />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={backButtonClicked}>{t('back')}</IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton hidden={!isPlatform('desktop')} href={downloadButtonClicked()} download={"Download.pdf"}>
                            <IonIcon slot="icon-only" icon={download} />
                        </IonButton>
                        <IonButton hidden={isPlatform('desktop')} onClick={() => shareButtonClicked()}>
                            <IonIcon slot="icon-only" icon={shareIcon} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{t('document')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                    <Document
                        file={urlObj}
                        onLoadSuccess={onDocumentLoadSuccess}
                        error={<FaraError/>}
                        loading={<FaraLoading />}
                    >
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                            <
                                // @ts-ignore
                                PinchToZoom key={index}>
                                <Page
                                    width={document.getElementById('main')?.clientWidth}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    loading={<></>}
                                />
                            </PinchToZoom>
                            ),
                        )}
                    </Document>

                <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={!needSig()}>
                    <IonFabButton routerLink={`/documentsign/${match.params.id}/${needEmployeeSig() ? "employee": "customer"}`} className={"signButtonPulse"} color={needEmployeeSig() ? "primary" : "secondary"}>
                        <IonIcon icon={pencil} />
                    </IonFabButton>
                    {/*<IonFabList side="top" style={{left: 0, right: 0}}>*/}
                    {/*    <IonButton routerLink={`/documentsign/${match.params.id}/customer`}>Unterschrift Kunde</IonButton>*/}
                    {/*    <IonButton routerLink={`/documentsign/${match.params.id}/employee`}>Unterschrift Mitarbeiter</IonButton>*/}
                    {/*</IonFabList>*/}
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

export default DocumentViewer;
