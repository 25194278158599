import React, {createContext, useContext, useEffect, useState} from "react";
import useFetch, {CachePolicies, IncomingOptions} from "use-http";
import {useTranslation} from "react-i18next";
import * as jwt from "jsonwebtoken";

const authContext = createContext();

export function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [login, setLogin] = useState(localStorage.getItem('login'));
    const {t} = useTranslation();

    useEffect(() => {
        if (localStorage.getItem('login')) {
            setLogin(localStorage.getItem('login'))
        }
    }, [login])

    const signin = async (username, password) => {
        // options.headers.Authorization = "Basic " + btoa(username + ":" + password)
        console.log("serverurl:" + process.env.REACT_APP_API_URL)
        return await fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'post',
            headers: {
                'Authorization': "Basic " + btoa(username + ":" + password)
            }
        })
            .then(function(response) {
                console.log(response.status); // Will show you the status
                if (!response.ok) {
                    throw new Error(t('loginError'));
                }
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('login', data.token);
                setLogin(data.token)
                console.log("setLogin in auth")
                return data.token
            })
    };

    const signout = () => {
        localStorage.removeItem('login');
        //setLogin(null)
    };

    const isExpired = () => {
        let token = localStorage.getItem('login')
        if (token && jwt.decode(token)) {
            const expiry = jwt.decode(token).exp;
            const now = new Date();
            return now.getTime() > expiry * 1000;
        }
        return false;
    }

    return {
        login,
        signin,
        signout,
        isExpired
    };

}