import {
    IonBackButton,
    IonButton,
    IonButtons, IonCol,
    IonContent, IonFab, IonFabButton, IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonPage, IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToast,
    IonToolbar,
    IonModal,
    useIonViewWillEnter
} from '@ionic/react';
import React, {useEffect, useRef, useState} from 'react';
import './Home.css';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import FaraError from "../components/FaraError";
import {camera, checkmark, close, pencil, refreshOutline} from "ionicons/icons";
import {CameraDirection, CameraPhoto, CameraResultType, Plugins} from '@capacitor/core';
// @ts-ignore
import SignaturePad from 'react-signature-pad-wrapper'
import {RouteComponentProps} from "react-router";

export type MyBewerberType = {
    // name: string
    // nachname: string
    // strasse: string,
    // ort: string,
    // hausnr: string,
    // plz: string,
    // tel: number,
    // mobil: string,
    // NL: string,
    // land: string,
    // berufsfeld: string,
    // einsatzort: string,
    feedback: string,
    // geschlecht: string,
    status: string,
    // createdAt: Date,
    // id: number
}

const BewerberFormular = (props: { setBewerber: any, isBewerberTypeAktiv: string }) => {
    const {t} = useTranslation();
    const { handleSubmit, control, errors } = useForm({shouldFocusError: true});
    const [errorInfo, setErrorInfo] = useState({showErrorToast: false, errMsg: ""});
    const [successfulSubmit, setSuccessfulSubmit] = useState({showSuccessfulSubmitToast: false, submitMsg: ""});

    const { Camera } = Plugins;
    const [photo, setPhoto] = useState<CameraPhoto>()
    const maxPhotoSize10MB = 10000000;
    const [isPhotoSizeOk, setIsPhotoSizeOk] = useState(false);

    const [signatureDataUrl, setSignatureDataUrl] = useState<string>()
    const [isSignatureOk, setIsSignatureOk] = useState(false);
    let signaturePad = useRef<SignaturePad>()
    const [showSignatureModal, setShowSignatureModal] = useState(false)
    const [canSend, setCanSend] = useState(false);
    const isBewerberTypeAktiv = props.isBewerberTypeAktiv === "true"

    const contentRef = useRef<HTMLIonContentElement | null>(null);

    const history = useHistory();

    const scrollToTop= () => {
        contentRef.current && contentRef.current.scrollToTop();
    };

    async function takePicture() {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.DataUrl,
            direction: CameraDirection.Front
        });
        setPhoto(image)
        setIsPhotoSizeOk(dataURLtoBlob(image.dataUrl).size <= maxPhotoSize10MB)
    }

    function toggleShowSignatureModal(show: boolean) {
        setCanSend(false)
        setShowSignatureModal(show)
    }

    function isSubmitButtonDisabled() {
        if (!isBewerberTypeAktiv){
            // 1. einfaches form
            return false
        } else {
            // 2. erw. Form
            return !isSignatureOk
        }
    }

    function saveSignature() {
        // @ts-ignore
        let signatureDataUrl = signaturePad.toDataURL();
        setSignatureDataUrl(signatureDataUrl)
        setShowSignatureModal(false)
        // @ts-ignore
        setIsSignatureOk(!signaturePad.isEmpty())
    }

    const onSubmit = (data: any) => {
        let formData = new FormData()
        formData.append('bewerber', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }))

        if (isBewerberTypeAktiv) {
            if(photo !== undefined) {
                formData.append("photo", dataURLtoBlob(photo?.dataUrl), "photo.png")
            }
            formData.append("signature", dataURLtoBlob(signatureDataUrl), "signature.png")
        }
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch(process.env.REACT_APP_API_URL+'/bewerber/', requestOptions)
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                localStorage.setItem("uuid", data.id)
                props.setBewerber(data)
                setSuccessfulSubmit({showSuccessfulSubmitToast: true, submitMsg: "test123"})
                setTimeout(() => {
                    history.goBack()
                }, 4000)
            })
            .catch(error => {
                setErrorInfo({showErrorToast: true, errMsg: error.message});
            });
    };

    function trimValue(e: any) {
        return e.target.value = e.target.value.trim()
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) scrollToTop()
    }, [errors])

    useEffect(() => {
        console.log("useEffect resize")
        window.dispatchEvent(new Event('resize'));
    })

    return <IonContent ref={contentRef} scrollEvents={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('applicationPreferredJob')}</IonLabel>
                </IonItemDivider>

                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationJob')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => trimValue(e)}/>)}
                        control={control}
                        name="berufsfeld"
                        rules={{
                            required: true,
                            minLength: 4,

                        }}
                    />
                    { errors.berufsfeld && (<IonText color="danger">
                        <p className="error-small">{t('applicationError')}</p>
                    </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationPlaceOfUse')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="einsatzort"
                        rules={{
                            required: true,
                            minLength: 4
                        }}
                    />
                    { errors.einsatzort && (<IonText color="danger">
                        <p className="error-small">{t('applicationError')}</p>
                    </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationBranch')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) => (
                            <IonSelect placeholder="Wähle aus" onIonChange={onChange}>
                                <IonSelectOption value="GIESSEN">Gießen</IonSelectOption>
                                <IonSelectOption value="FPV_BAD_HOMBURG">Bad Homburg</IonSelectOption>
                                <IonSelectOption value="NIDDA">Nidda</IonSelectOption>
                                <IonSelectOption value="HANAU">Hanau</IonSelectOption>
                            </IonSelect>
                        )}
                        control={control}
                        name="NL"
                        rules={{ required: true }}
                    />
                    { errors.NL && (<IonText color="danger">
                        <p className="error-small">{t('applicationError')}</p>
                    </IonText>)}
                </IonItem>
            </IonItemGroup>
            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('applicationPersonalData')}</IonLabel>
                </IonItemDivider>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationFirstname')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="vorname"
                        rules={{
                            required: true,
                            minLength: 2
                        }}
                    />
                    {errors.vorname && (<IonText color="danger">
                        <p className="error-small">{t('applicationError')}</p>
                    </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationLastname')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="nachname"
                        rules={{
                            required: true,
                            minLength: 2
                        }}
                    />
                    { errors.nachname && (
                        <IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>
                        )}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationGender')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) => (
                            <IonSelect placeholder={t('applicationChoose')} onIonChange={onChange}>
                                <IonSelectOption value="MAENNLICH">{t('male')}</IonSelectOption>
                                <IonSelectOption value="WEIBLICH">{t('female')}</IonSelectOption>
                                <IonSelectOption value="DIVERS">{t('divers')}</IonSelectOption>
                            </IonSelect>
                        )}
                        control={control}
                        name="geschlecht"
                        rules={{ required: true }}
                    />
                    { errors.geschlecht && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationStreet')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="strasse"
                        rules={{
                            required: true,
                            minLength: 3
                        }}
                    />
                    { errors.strasse && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationHouseNumber')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="hausnr"
                        rules={{
                            required: true,
                            minLength: 1
                        }}
                    />
                    { errors.hausnr && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationCity')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="ort"
                        rules={{
                            required: true,
                            minLength: 4
                        }}
                    />
                    { errors.ort && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationPostcode')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput type="number" onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="plz"
                        rules={{
                            required: true,
                            minLength: 5
                        }}
                    />
                    { errors.plz && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationCountry')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="land"
                        rules={{
                            required: true,
                            minLength: 2
                        }}
                    />
                    { errors.land && (<IonText color="danger">
                        <p className="error-small">{t('applicationError')}</p>
                    </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationPhoneNumer')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput type="number" onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="tel"
                        rules={{
                            required: true,
                            minLength: 7
                        }}
                    />
                    { errors.tel && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationMobileNumber')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput type="number" onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="mobil"
                        rules={{
                            required: true,
                            minLength: 5
                        }}
                    />
                    { errors.mobil && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
                <IonItem>
                    <IonLabel color="medium" position="floating">{t('applicationEmail')}:</IonLabel>
                    <Controller
                        render={({ onChange, onBlur, value }) =>
                            (<IonInput onIonChange={onChange} onIonBlur={(e: any) => {trimValue(e)}}/>)}
                        control={control}
                        name="email"
                        rules={{
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "sdfsdfds"
                            }
                        }}
                    />
                    { errors.email && (<IonText color="danger">
                            <p className="error-small">{t('applicationError')}</p>
                        </IonText>)}
                </IonItem>
            </IonItemGroup>
            {isBewerberTypeAktiv &&
                <div>
                    <IonItemGroup>
                        <IonItemDivider>
                            <IonLabel>{t('applicationPhoto')}</IonLabel>
                        </IonItemDivider>
                        {(photo && isPhotoSizeOk) && <img className={"applicationPhoto ion-padding-top"} alt="photo" src={photo?.dataUrl}/>}

                        <div className="ion-text-center">
                            {(photo && !isPhotoSizeOk) && (<IonText color="danger">
                            <span className="error-small">
                                <IonText color="primary">
                                  <h4>{t('applicationFileTooLarge')}</h4>
                                </IonText>
                            </span>
                            </IonText>)}
                        </div>

                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol className="ion-text-center">
                                    <IonFabButton color="farared" className={"ion-fab-button-center"} onClick={takePicture}>
                                        <IonIcon icon={camera} />
                                    </IonFabButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItemGroup>
                    <IonItemGroup>
                        <IonItemDivider>
                            <IonLabel>{t('applicationSignature')}</IonLabel>
                        </IonItemDivider>

                        <IonModal isOpen={showSignatureModal}>
                            <IonGrid>
                                <IonRow className=" ion-align-items-stretch">
                                    <IonCol className="ion-no-margin">
                                        <div className="signatureborder">
                                            <SignaturePad ref={(ref: React.MutableRefObject<any>) => signaturePad = ref}
                                                          onPress={() => signaturePad.current.clear()} redrawOnResize={true}
                                                          options={{backgroundColor: "rgb(255,255,255)",
                                                              onBegin: () => setCanSend(true)}}/>
                                        </div>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        <h1 className="">
                                            ↑
                                            <p>
                                                Bitte in diesem Bereich unterschreiben!
                                            </p>
                                        </h1>
                                    </IonCol>
                                </IonRow>
                                <IonRow className=" ion-align-items-stretch">
                                    <IonCol className="ion-no-margin ion-center">

                                    </IonCol>
                                </IonRow>

                            </IonGrid>
                            <IonFab vertical="bottom" horizontal="start" slot="fixed">
                                <IonFabButton color={"success"} disabled={!canSend} onClick={saveSignature}>
                                    <IonIcon icon={checkmark}/>
                                </IonFabButton>
                            </IonFab>
                            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                                <IonFabButton onClick={() => toggleShowSignatureModal(false)}>
                                    <IonIcon icon={close}/>
                                </IonFabButton>
                            </IonFab>
                        </IonModal>

                        {/* // @ts-ignore */}
                        {isSignatureOk && <img className={"applicationPhoto ion-padding-top signature-border-bottom"} alt="signature" src={signatureDataUrl}/>}
                        <IonGrid>
                            <IonRow>
                                <IonCol className="ion-text-center">
                                    <IonFabButton color="farared" className={"ion-fab-button-center"} onClick={() => toggleShowSignatureModal(true)}>
                                        <IonIcon icon={pencil} />
                                    </IonFabButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItemGroup>
                </div>
            }
            <IonButton expand="block" className={"ion-margin"} type="submit" disabled={isSubmitButtonDisabled()}>{t('send')}</IonButton>
        </form>
            <IonToast
                color="danger"
                isOpen={errorInfo.showErrorToast}
                onDidDismiss={() => setErrorInfo({errMsg: "", showErrorToast: false})}
                message={t("failedToFetch")}
                duration={2000}
            />
            <IonToast
                color="success"
                isOpen={successfulSubmit.showSuccessfulSubmitToast}
                onDidDismiss={() => setSuccessfulSubmit({submitMsg: "", showSuccessfulSubmitToast: false})}
                message={t("applicationSent")}
                duration={3800}
            />
        </IonContent>
}

const Bewerber: React.FC<RouteComponentProps<{ isBewerberTypeAktiv: string }>> = ({match}) => {
    const { t } = useTranslation();
    const [bewerber, setBewerber] = useState<MyBewerberType>()

    const isBewerber = match.params.isBewerberTypeAktiv === 'true'

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton text={t('back')} defaultHref="home"/>
                </IonButtons>
                <IonTitle>{(hasBewerberUuid() && !isBewerber) ? t('applicationTitleStatus') : t('applicationTitleToFill')}</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent>
            {(!hasBewerberUuid() || isBewerber ) && <BewerberFormular setBewerber={setBewerber} isBewerberTypeAktiv={match.params.isBewerberTypeAktiv}/>}
            {(hasBewerberUuid() && !isBewerber ) && <BewerberStatus bewerber={bewerber} setBewerber={setBewerber}/>}
        </IonContent>
    </IonPage>
};

const BewerberStatus = (props: { bewerber: any, setBewerber: any }) => {
    const { t } = useTranslation();
    const [error, setError] = useState(false)

    useIonViewWillEnter(() => {
        fetchStatus()
    });

    const fetchStatus = () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        return fetch(process.env.REACT_APP_API_URL+'/bewerber/'+getBewerberUuid(), requestOptions)
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    setError(true)
                }
            })
            .then((json) => {
                props.setBewerber(json)
            })
    }
    if (error){
        return <FaraError/>
    }
    return <IonContent>
        <div className={"bewerberstatus ion-padding"}>
            <IonText>
                <h1><b>{t('applicationStatus')}: {t(mapBewerberStatus(props.bewerber?.status))}</b></h1>
                <h4>{t('applicationFeedback')}: {props.bewerber?.feedback}</h4>
            </IonText>
        </div>
        <IonButton onClick={() => fetchStatus()}
                   className={"ion-margin"} fill={"outline"} expand="block" color={"farared"}>
            <IonIcon slot="icon-only" icon={refreshOutline} style={{marginRight: "0.25em"}}/>
            <p style={{fontSize: "0.825em"}}>{t('applicationRefresh')}</p>
        </IonButton>
    </IonContent>;
};

function hasBewerberUuid() {
    return localStorage.getItem("uuid") !== null
}

function getBewerberUuid() {
    return localStorage.getItem("uuid")
}

function mapBewerberStatus(status?: string) {
    console.log('mapBewerberStatus' + status)
    switch (status) {
    case "GESENDET":
            return "SEND"
    case "BEANTWORTET":
        return "ANSWERED"
    case "ABGELEHNT":
        return "DECLINED"
    default:
        return ""
    }
}

function dataURLtoBlob(dataurl: string | undefined) {
    // @ts-ignore
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

export default Bewerber;
