import React from 'react';
import './ExploreContainer.css';
import {IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner} from "@ionic/react";
import {warning} from "ionicons/icons";

interface ContainerProps {
}

const FaraLoading: React.FC<ContainerProps> = () => {
    return <IonGrid>
        <IonRow className="ion-justify-content-center">
            <IonCol className="ion-text-center">
                <IonSpinner/>
            </IonCol>
        </IonRow>
    </IonGrid>
};

export default FaraLoading;
