import {useEffect} from "react";
import {useAuth} from "./use-auth.js";
import {useIonRouter} from "@ionic/react";

export default function useRequireAuth(redirectUrl = '/login'){
    const auth = useAuth();
    const router = useIonRouter();

    useEffect(() => {
        if (auth.login === null || auth.isExpired()){
            router.push(redirectUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, redirectUrl]);

    return auth;
}