import {
    IonBackButton,
    IonBadge,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar, useIonViewWillEnter
} from '@ionic/react';
import React, {useEffect} from 'react';
import './Home.css';
import useStore, {FaraState} from '../StateStore'
import useFetch, {CachePolicies, CustomOptions, IncomingOptions} from "use-http";
import {useTranslation} from "react-i18next";
import FaraLoading from "../components/FaraLoading";
import FaraError from "../components/FaraError";
import useLocalStorage from "../hooks/use-localstorage";
import {useAuth} from "../hooks/use-auth";

export type MyDocType = {
    name: string
    id: string
    type: string,
    seen: boolean,
    customerSignature: SigStatus | null,
    employeeSignature: SigStatus | null,
    createdAt: Date
}

export type SigStatus = {
    status: string
}

const Documents: React.FC = () => {
    const { t } = useTranslation();
    const auth = useAuth();

    const employee = useStore(state => state.employee)
    const store = useStore()

    useIonViewWillEnter(() => {
        store.fetchDocuments(auth)
    });

    if (!employee?.documents) {
        return null
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={t('back')} defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>{t('documentCategorie')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <DocumentCategories data={employee?.documents}/>
            </IonContent>
        </IonPage>
    );
};

const DocumentCategories = (props: { data: MyDocType[] }) => {
    const {t} = useTranslation();
    let grops = groupBy(props.data, data => data.type)

    return (
        <IonList>
            {Object.keys(grops).sort().map(g => {
                let length = grops[g].filter(x => !x.seen).length;
                return <IonItem key={g} routerLink={"/documents/categorie/" + g}>
                        <IonLabel>{t(g)}</IonLabel>
                    {length > 0 && <IonBadge color="farared" slot="end">{length}</IonBadge>}
                    </IonItem>;
                }
            )}
        </IonList>);
};

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export default Documents;
