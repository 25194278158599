import {
    IonBackButton,
    IonBadge,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter,
    IonSegment,
    IonSegmentButton,
    IonFabButton,
    IonFab,
    IonIcon,
    IonButton,
    IonDatetime,
    IonInput,
    IonItemDivider,
    IonRow,
    IonCol, useIonRouter, IonToast, IonText, IonAlert, useIonLoading, IonLoading
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import '../Home.css';
import useStore, {FaraState, TimeSheetType} from '../../StateStore'
import useFetch, {CachePolicies, CustomOptions, IncomingOptions} from "use-http";
import {useTranslation} from "react-i18next";
import FaraLoading from "../../components/FaraLoading";
import FaraError from "../../components/FaraError";
import useLocalStorage from "../../hooks/use-localstorage";
import {add, checkmark, pencil, duplicate, arrowBack, arrowForward} from 'ionicons/icons';
import {useAuth} from "../../hooks/use-auth";
import moment from "moment";
import {Controller, useForm} from 'react-hook-form';
import {loadingController} from "@ionic/core";
import {useController} from "@ionic/react/dist/types/hooks/useController";

const AddTimeSheet: React.FC = () => {
    const {t} = useTranslation();
    const auth = useAuth();
    const router = useIonRouter()
    const [weekdate, setWeekDate] = useState(new Date());
    const [currentWeekdate] = useState(new Date());
    const {control, handleSubmit, errors, formState} = useForm()

    const addTimeSheet = useStore(state => state.addTimeSheet)

    function decreaseWeek() {
        setWeekDate(moment(weekdate).subtract(1, "week").toDate())
    }

    function increaseWeek() {
        setWeekDate(moment(weekdate).add(1, "week").toDate())
    }

    function createTimeSheet(data: any) {
        const timesheet: TimeSheetType = {
            kunde: data.kunde,
            von: moment(weekdate).day(1).toDate(),
            bis: moment(weekdate).day(6).toDate(),
        }

        return addTimeSheet(timesheet).then(() => router.back())
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={t('back')} defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>{t('timetrack')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonRow className={"ion-margin-vertical ion-padding-vertical"}>
                    <IonCol className={"ion-text-right"}>
                        <IonButton
                            size={"large"}
                            disabled={moment(weekdate).isSameOrBefore(moment(currentWeekdate).subtract(5, "week"))}
                            onClick={() => decreaseWeek()}>
                            <IonIcon icon={arrowBack} />
                        </IonButton>
                    </IonCol>
                    <IonCol className={"ion-center col-auto"}>
                        <IonLabel>
                            <h1>{t('weekNumber')}</h1>
                            <h1 className={"ion-center"}>{moment(weekdate).format("W")}</h1>
                        </IonLabel>
                    </IonCol>
                    <IonCol className={"ion-text-left"}>
                        <IonButton
                            size={"large"}
                            disabled={!(moment(weekdate).isBefore(moment(currentWeekdate).add(1, "week")))}
                            onClick={() => increaseWeek()}>
                            <IonIcon icon={arrowForward} />
                        </IonButton>
                    </IonCol>
                </IonRow>

                <IonList className={"ion-padding-vertical"}>
                        <IonItem color={"light"}>
                            <IonLabel className={"ion-text-center"}>
                                <h1>
                                    {moment(weekdate).day(1).format("DD.MM.YYYY")} - {moment(weekdate).day(7).format("DD.MM.YYYY")}
                                </h1>
                            </IonLabel>
                        </IonItem>
                </IonList>

                <form onSubmit={handleSubmit(createTimeSheet)}>
                    <IonItem className={"ion-padding"}>
                        <IonLabel>{t('customer')}: *</IonLabel>
                        <Controller
                            render={({ onChange, onBlur, value }) => (<IonInput onIonChange={onChange}/>)}
                            name={"kunde"}
                            defaultValue={""}
                            rules={{
                                required: true,
                            }}
                            control={control}
                        />
                    </IonItem>

                    <IonAlert
                        isOpen={!!errors.kunde}
                        header={t('error')}
                        message={t('errorNeedsCustomer')}
                        buttons={['OK']}
                    />

                    <IonLoading isOpen={formState.isSubmitting}/>
                    <IonButton expand={"block"} className={"ion-margin-vertical"} type={"submit"}>
                        <IonIcon slot="start" icon={duplicate}/>
                        {t('create')}
                    </IonButton>
                </form>

            </IonContent>
        </IonPage>
    );
};

export default AddTimeSheet;
