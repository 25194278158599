import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter} from '@ionic/react';
import React from 'react';
// @ts-ignore
import {Document} from 'react-pdf/dist/esm/entry.webpack';
import './Home.css';
import {RouteComponentProps, useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useAuth} from "../hooks/use-auth";
import useStore from "../StateStore";
import {dataURLtoBlob, FaraSignature} from "../FaraSignature";

const DocumentSignature: React.FC<RouteComponentProps<{ id: string, who: string }>> = ({match}) => {
    const {t} = useTranslation();
    const auth = useAuth()
    const history = useHistory()
    const store = useStore()

    function saveSignature(signaturePad: any) {
        // @ts-ignore
        let data = signaturePad.toDataURL();

        const formData = new FormData();
        formData.append("file", dataURLtoBlob(data))

        fetch(`${process.env.REACT_APP_API_URL}/document/signature/${match.params.id}/${match.params.who}`, { // Your POST endpoint
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth.login}`,
            },
            body: formData
        }).then(
            response => response.json()
        ).then(
            success => {
                store.fetchDocuments(auth)
                history.goBack()
            }
        ).catch(
            error => console.log(error)
        );
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('signature')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <FaraSignature onClick={saveSignature}/>
            </IonContent>
        </IonPage>
    );
};

export default DocumentSignature;
