import {
    IonBackButton,
    IonButton,
    IonButtons, IonCol,
    IonContent,
    IonDatetime, IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem, IonItemDivider,
    IonLabel, IonLoading,
    IonPage, IonRow, IonSpinner,
    IonTitle,
    IonToolbar, useIonLoading,
    useIonRouter, useIonToast
} from '@ionic/react';
import React from 'react';
import '../Home.css';
import useStore, {TimeSheetType, TimeTrackType} from '../../StateStore'
import {useTranslation} from "react-i18next";
import {save, trash} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";

const AddTimeTrackItem: React.FC<RouteComponentProps<{ forid: string, day: string, id: string, sheetid: string }>> = ({match}) => {
    const { t } = useTranslation();
    const addTimeTrack = useStore(state => state.addTimeTrack)
    const editTimeTrack = useStore(state => state.editTimeTrack)
    const deleteTimeTrack = useStore(state => state.deleteTimeTrack)

    const timeSheets = useStore(state => state.timesheets)
    const {control, handleSubmit, errors, getValues, formState} = useForm()
    const router = useIonRouter()
    const [present, dismiss] = useIonToast();
    const [presentLoading, dismissLoading] = useIonLoading();

    function createPrettyOverlapError(validations: any[]) {
        return validations.map(x => moment(x.einzelnachweis.von).format("HH:mm") + " - " + moment(x.einzelnachweis.bis).format("HH:mm") +" "+ t('costCentre')+": "+x.einzelnachweis.kostenstelle).join("\n")
    }

    function handleOverlapError(data: any) {
        return data.json().then((data: any) => present(t('errorOverlap') + "\n" + createPrettyOverlapError(data.validations), 10000))
    }

    function createTimeTrack(data: any) {
        if (match.params.id && match.params.sheetid) {
            let einzelnachweis = findEintragById(timeSheets, match.params.id)!!
            let bis = moment(einzelnachweis.bis).hour(moment(data.bis).hour()).minute(moment(data.bis).minute());

            if (moment(data.bis).isBefore(moment(data.von))) {
                bis.add(1, "day")
            } else {
                bis.day(moment(einzelnachweis.von).day())
            }

            const timetrack: TimeTrackType = {
                von: moment(einzelnachweis.von).hour(moment(data.von).hour()).minute(moment(data.von).minute()).toDate(),
                bis: bis.toDate(),
                pausebegin: moment.parseZone(data.pausebegin).toDate(),
                pause: data.pause,
                kostenstelle: data.kostenstelle
            }
            return editTimeTrack(match.params.sheetid, match.params.id, timetrack).then((data) => {
                data.ok ? router.back() : handleOverlapError(data)
            })

        } else {
            let timeSheet = findTimeSheetById(timeSheets, match.params.forid)!!
            let bis = moment(timeSheet.bis).day(match.params.day).hour(new Date(data.bis).getHours()).minute(new Date(data.bis).getMinutes());

            if (moment(data.bis).isBefore(moment(data.von))) {
                bis.add(1, "day")
            }

            const timetrack: TimeTrackType = {
                von: moment(timeSheet.von).day(match.params.day).hour(new Date(data.von).getHours()).minute(new Date(data.von).getMinutes()).toDate(),
                bis: bis.toDate(),
                pausebegin: moment.parseZone(data.pausebegin).toDate(),
                pause: data.pause,
                kostenstelle: data.kostenstelle
            }
            return addTimeTrack(match.params.forid, timetrack).then((data) => {
                data.ok ? router.back() : handleOverlapError(data)
            })
        }
    }

    function onDeleteClicked() {
        deleteTimeTrack(match.params.sheetid, match.params.id).then(() => router.back())
    }
    function vonBeforeBisValidator() {
        return moment(getValues("von")).isBefore(moment(getValues("bis")), "minutes")
    }
    function enoughPauseValidator() {
        const dur = Math.abs(moment.duration(moment(getValues("von")).diff(moment(getValues("bis")))).asMinutes());
        const pause = getValues("pause")

        if (dur <= 360) {
            return true
        } else if (dur > 360 && dur <= 540) {
            return (pause && pause >= 30)
        } else if (dur > 540 && dur < 600) {
            return (pause && pause >= 45)
        } else {
            return false
        }
    }

    let einzelnachweis = findEintragById(timeSheets, match.params.id)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={t('back')} defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>{t('timetrack')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={"ion-padding"}>
                <IonLoading isOpen={formState.isSubmitting}/>

                <form onSubmit={handleSubmit(createTimeTrack)}>
                    <IonItem>
                        <IonLabel color={errors.von? "danger": "default"}>{t('timeFrom')+"*"}</IonLabel>
                        <Controller
                            render={({ onChange, onBlur, value }) => (<IonDatetime onIonBlur={onBlur} value={value} onIonChange={onChange} displayFormat="HH:mm" placeholder={"--:--"} />)}
                            name={"von"}
                            defaultValue={einzelnachweis?.von}
                            control={control}
                            rules={{
                                required: true,
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel color={errors.bis? "danger": "default"}>{t('timeTo')+"*"}</IonLabel>
                        <Controller
                            render={({ onChange, onBlur, value }) => (<IonDatetime onIonBlur={onBlur} value={value} onIonChange={onChange} displayFormat="HH:mm" placeholder={"--:--"} />)}
                            name={"bis"}
                            defaultValue={einzelnachweis?.bis}
                            rules={{
                                required: true,
                            }}
                            control={control}
                        />
                    </IonItem>
                    <IonItemDivider color={"light"}/>
                    <IonItem>
                        <IonLabel color={errors.pause? "danger": "default"}>{t('breaktimeMin')}</IonLabel>
                        <Controller
                            render={({ onChange, onBlur, value }) => (<IonInput inputmode={"decimal"} onIonBlur={onBlur} value={value} onIonChange={onChange} />)}
                            name={"pause"}
                            defaultValue={einzelnachweis?.pause}
                            rules={{
                                required: false,
                                pattern: /^[0-9]+$/,
                                validate: enoughPauseValidator
                            }}
                            control={control}
                        />
                    </IonItem>
                    {
                        errors.pause && errors.pause.type === "validate" && (
                            <IonLabel color={errors.pause? "danger": "default"}>{t('workTimeLawAlert')}</IonLabel>
                        )
                    }
                    <IonItem>
                        <IonLabel color={errors.pausebegin? "danger": "default"}>{t('timeBreak')}</IonLabel>
                        <Controller
                            render={({ onChange, onBlur, value }) => (<IonDatetime onIonBlur={onBlur} value={value} onIonChange={onChange} displayFormat="HH:mm" placeholder={"--:--"} />)}
                            name={"pausebegin"}
                            defaultValue={einzelnachweis?.pausebegin}
                            rules={{
                                required: false
                            }}
                            control={control}
                        />
                    </IonItem>
                    <IonItemDivider color={"light"}/>
                    <IonItem>
                        <IonLabel color={errors.kostenstelle? "danger": "default"} position="stacked">{t('workunit')}</IonLabel>
                        <Controller
                            render={({ onChange, onBlur, value }) => (<IonInput onIonChange={onChange} onIonBlur={onBlur} value={value} />)}
                            control={control}
                            defaultValue={einzelnachweis?.kostenstelle}
                            rules={{
                                required: false,
                            }}
                            name={"kostenstelle"}
                        />
                    </IonItem>
                    <IonButton hidden={!match.params.forid} expand={"block"} className={"ion-margin-vertical"} type={"submit"}>
                        <IonIcon slot="start" icon={save}/>
                        {t('save')}
                    </IonButton>
                    <IonGrid hidden={!match.params.id}>
                        <IonRow className="ion-justify-content-center">
                            <IonCol>
                                <IonButton fill={"outline"} expand={"block"} onClick={() => onDeleteClicked()}>
                                    <IonIcon slot="start" icon={trash}/>
                                    {t('delete')}
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton expand={"block"} type={"submit"}>
                                    <IonIcon slot="start" icon={save}/>
                                    {t('save')}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
            </IonContent>
        </IonPage>
    );
};

function findEintragById(timeSheet: TimeSheetType[] | null, id: string):TimeTrackType | undefined {
    return timeSheet?.flatMap(x => x.einzelnachweise).filter(x => x?.id === id)[0];
}

function findTimeSheetById(timeSheet: TimeSheetType[] | null, id: string):TimeSheetType | undefined {
    return timeSheet?.filter(x => x?.id === id)[0];
}

export default AddTimeTrackItem;
