import create from "zustand";
import { combine } from 'zustand/middleware'
import {MyDocType} from "./pages/Documents";

export type EmplyeeType = {
    vorname: string
    nachname: string
    niederlassung: string
    employeeId: string
    id: string
    type: string,
    seen: boolean,
    createdAt: Date,
    documents?: MyDocType[],
    login?: LoginType
}

export type LoginType = {
    username: string,
    enabled: boolean,
    roles: RoleType[]
}

export type RoleType = {
    id: string,
    username: string,
    role: string,
}

export type TimeTrackType = {
    id?: string,
    von: Date,
    bis: Date,
    pausebegin: Date,
    kostenstelle?: string,
    pause: number
}

export type TimeSheetType = {
    kunde: string
    von: Date
    bis: Date
    id?: string
    einzelnachweise?: TimeTrackType[]
}

export type FaraState = {
    employee: EmplyeeType | null
    fetchDocuments: (auth: any) => void
    timesheets: TimeSheetType[] | null
    fetchTimeSheets: (auth: any) => Promise<any>
    addTimeSheet: (newTimesheet: TimeSheetType) => Promise<Response>
    deleteTimeSheet: (id: string) => Promise<Response>
    addTimeTrack: (forId: string, newTimesheet: TimeTrackType) => Promise<Response>
    editTimeTrack: (forId: string, id: string, newTimesheet: TimeTrackType) => Promise<Response>
    deleteTimeTrack: (forId: string, id: string) => Promise<Response>
    hasRole(roleadmin: string): boolean;
}

function createAuthHeaders() {
    return {
        'Authorization': `Bearer ${localStorage.getItem('login')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const useStore = create<FaraState>(set => ({
    fetchDocuments: async (auth: any) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/employee/me`, {
            method: 'get',
            headers: createAuthHeaders()}
        )

        set({ employee: await response.json() })
    },
    employee: null,
    timesheets: null,
    fetchTimeSheets: (auth: any) => {
        return fetch(`${process.env.REACT_APP_API_URL}/timetrack`, {
            method: 'get',
            headers: createAuthHeaders()}
        ).then(res => res.json()).then(res => set({ timesheets: res }))
    },
    addTimeSheet: (newTimesheet: TimeSheetType) => {
        return fetch(`${process.env.REACT_APP_API_URL}/timetrack`, {
            method: 'POST',
            headers: createAuthHeaders(),
            body: JSON.stringify(newTimesheet)
        })
    },
    deleteTimeSheet: (id: string) => {
        return fetch(`${process.env.REACT_APP_API_URL}/timetrack/${id}`, {
            method: 'DELETE',
            headers: createAuthHeaders(),
        })
    },
    addTimeTrack: (forId: string, newTimeTrack: TimeTrackType) => {
        return fetch(`${process.env.REACT_APP_API_URL}/timetrack/${forId}`, {
            method: 'POST',
            headers: createAuthHeaders(),
            body: JSON.stringify(newTimeTrack)
        })

        // let newItem = await response.json();
        // set(state => ({ timesheets: state.timesheets.concat(newItem)  }))
    },
    editTimeTrack: (forId: string, id: string, newTimesheet: TimeTrackType) => {
        return fetch(`${process.env.REACT_APP_API_URL}/timetrack/${forId}/${id}`, {
            method: 'PUT',
            headers: createAuthHeaders(),
            body: JSON.stringify(newTimesheet)
        })
    },
    deleteTimeTrack: (forId: string, id: string) => {
        return fetch(`${process.env.REACT_APP_API_URL}/timetrack/${forId}/${id}`, {
            method: 'DELETE',
            headers: createAuthHeaders(),
        })
    },
    hasRole(whatRole: string): boolean {
        return this.employee?.login?.roles.find(x => x.role === whatRole)?.role === whatRole ?? false
    }
}))

export default useStore