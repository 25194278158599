import {
    IonAvatar,
    IonBackButton, IonButton,
    IonButtons,
    IonContent,
    IonHeader, IonIcon,
    IonImg, IonItem, IonLabel, IonList, IonListHeader,
    IonNav,
    IonPage,
    IonTitle,
    IonToolbar, useIonViewWillEnter, IonText
} from '@ionic/react';
import {pin, document, ellipse, warning, walk, star} from 'ionicons/icons';

import React from 'react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import {FunctionalComponent} from "ionicons/dist/types/stencil-public-runtime";
import {RouteComponentProps} from "react-router";
import type { MyDocType } from "./Documents";
import useStore from "../StateStore";
import {useTranslation} from "react-i18next";
import useRequireAuth from "../hooks/use-require-auth";

interface DocumentItem {
    fileName: string,
    fileType: string,
    date: string,
    id: number
}

const DocumentListItem  = (props: { item: MyDocType }) => {
    const {t} = useTranslation();
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    function needEmployeeSig() {
        return props.item.employeeSignature?.status === "NEED_SIGNATURE";
    }

    function needCustomerSig() {
        return props.item.customerSignature?.status === "NEED_SIGNATURE";
    }

    function getIconColor() {
        if (props.item.employeeSignature?.status === "DONE") {
            return "success"
        }
        if (needEmployeeSig()) {
            return "danger"
        }
        if (needCustomerSig()) {
            return "secondary"
        }
        return "";
    }
    let date = new Date(props.item.createdAt).toLocaleDateString('de-DE', options)

    return <IonItem routerLink={`/document/${props.item.id}`}>
        <IonIcon icon={document} slot="start" color={getIconColor()}/>
        <IonLabel>
            <h2 style={{whiteSpace: "pre-line"}}>{props.item.seen ? props.item.name : <b>{props.item.name}</b>}</h2>
            <h4>{props.item.seen ? date: <b>{date}</b>}</h4>
            <p>{t(props.item.type)}</p>
            {needEmployeeSig() &&
            <IonText color="danger">
                <b>{t('pleaseSign')}</b>
            </IonText>}
        </IonLabel>
    </IonItem>;
}

const DocumentsList: React.FC<RouteComponentProps<{ cat: string }>> = (props) => {
    const documentStore = useStore()
    const {t} = useTranslation();
    const auth = useRequireAuth();
    const store = useStore()

    useIonViewWillEnter(() => {
        store.fetchDocuments(auth)
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={t('back')} defaultHref="/documents"/>
                    </IonButtons>
                    <IonTitle>{t('documents')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonListHeader>
                        {t(props.match.params.cat)}
                    </IonListHeader>

                    {documentStore.employee?.documents?.filter(x => x.type === props.match.params.cat)
                        .map(item => <DocumentListItem key={item.id} item={item}/>)}

                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default DocumentsList;
