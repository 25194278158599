import {
    IonBadge,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol, IonContent,
    IonGrid,
    IonImg,
    IonRow, IonText, useIonRouter
} from "@ionic/react";
import React from "react";
import {MyDocType} from "./Documents";
import {useTranslation} from "react-i18next";
import useStore from "../StateStore";
import FaraLoading from "../components/FaraLoading";

const NewsCard = () => {
    const {t} = useTranslation();
    const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
    const employee = useStore(state => state.employee)
    const router = useIonRouter();


    return <section className="ion-margin">
        <IonCard>
            <IonCardContent style={{paddingBottom: 0}}>
                <h1><b>{t('news')}</b></h1>
                {!employee?.documents && <FaraLoading />}
            </IonCardContent>
            {employee?.documents?.length === 0 && <IonCardContent><IonText><h1>{t('nonews')}</h1></IonText></IonCardContent>}
            {employee?.documents?.length !== 0 && employee?.documents?.slice(0, 3)
                    .map(item =>
                        <IonCardContent key={item.id} className={"elevation"}>
                            <IonText onClick={() => router.push(`/document/${item.id}`)}>
                                <h2><b>Neues Dokument - {item.name}</b></h2>
                                <h2><IonBadge color="primary">{t(item.type)}</IonBadge></h2>

                            </IonText>
                        </IonCardContent>
                    )}

            {employee && <IonCardContent>
                <ul>
                    <li>
                        <h2><b>
                            {t('unreadDocument', { count: employee?.documents?.filter(x => !x.seen).length})}
                        </b></h2>
                    </li>
                    <li>
                        <h2><b>
                            {t('unreadDocumentToSign', { count: employee?.documents?.filter(x => x.employeeSignature?.status === "NEED_SIGNATURE").length})}
                        </b></h2>
                    </li>
                </ul>
            </IonCardContent>}
        </IonCard>
    </section>
}

export default NewsCard;